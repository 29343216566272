<template>
  <v-btn
    @click="changeDeliveryStatus"
    color="primary"
    small
  >
    {{ 'ec.action.update_delivery_status'| t }}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  methods: {
    changeDeliveryStatus() {
      this.$apopup.base({
        title: this.$t('ec.action.update_delivery_status'),
        originDeliveryStatus: this.originDeliveryStatus,
        bodySlot: () => import('./changeDeliveryStatusPopup.vue'),
        disabledApply: data => {
          if(!data) return true
          return data.status == this.originDeliveryStatus
        },
        applyCallback: this.updateDeliveryStatus,
      })
    },
    async updateDeliveryStatus(params) {
      try {
        await this.$api.collection.deliveryApi.updateStatus(this.orderId, params)
        this.$snotify.success(null, this.$t('save.successfully'))
      } catch (error) {
        console.error(error)
        this.$snotify.error(this.$t('error.unexpect'), this.$t('save.failure'))
      } finally {
        this.formActions.initForm()
      }
    },
  },
  computed: {
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    orderId() {
      if(!this.formData) return null
      return window.eagleLodash.get(this.formData, 'id')
    },
    originDeliveryStatus() {
      if(!this.formData) return null
      return window.eagleLodash.get(this.formData, 'delivery_status')
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
